import React from "react";
import HeroImage from "../components/heroImage";
import CaveImg from "../assets/iNeedToGo/ElectricSheep.png";
import { Link } from "react-router-dom";

export default function projects() {
  return (
    <div>
      <HeroImage />
        <div className="flex items-start md:items-start justify-center h-[70vh]">
      <Link to="/i-need-to-go">
          <div className="bg-slate-800 bg-opacity-60 rounded-lg text-center">
            <div className="text-thisGray text-headSize2 font-Heading">
              I Need To <span className="text-red-600">Go</span>
            </div>
            <div className="text-paragraphSize1 font-Paragraph text-thisGreen">
              A nostalgic point-and-click game<br></br> where humor meets the
              heart.
            </div>
            <div className="relative">
              <div className="absolute text-white left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] font-Heading text-headSize3 bg-slate-900 bg-opacity-80 rounded-full px-4">
                IN PROGRESS
              </div>
              <img src={CaveImg} alt="just a cave" className="h-[400px] p-4" />
            </div>
            <div className="text-thisGreen">
              Follow the development via our Discord. Click{" "}
              <a
                href="https://discord.gg/pKGE5dxjAk"
                className="text-white"
                rel="noreferrer"
                target="_blank"
              >
                HERE
              </a>{" "}
              to join.
            </div>
          </div>
      </Link>
        </div>
    </div>
  );
}
