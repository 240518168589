import Home from "./pages/home"
import Header from "./components/header";
//import Blog from "./pages/blogHome"
import Contact from "./pages/contact"
import Projects from "./pages/projects";
import Footer from "./components/footer";
import FormGameTest from "./components/formGameTest";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FormNewsletter from "./components/formNewsletter";
import Ineedtogo from "./pages/ineedtogo";


function App() {
  return (
    <div className="App overflow-hidden">
      <BrowserRouter>
      {/* Need to make Home component, Blog-component and Contact-component
      Then header and footer(?) is constant: */}
      <Header className="sticky top-0 z-50"/>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/newsletter' element={<FormNewsletter />}/>
          <Route path='/game-test' element={<FormGameTest />}/>
          <Route path='/projects' element={<Projects />}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path='/i-need-to-go' element={<Ineedtogo />}/>
        </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
